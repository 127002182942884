import { createRouter, createWebHashHistory } from "vue-router";
import Home from "@/views/Home";
import Otp from "@/views/Otp";
import RenewPackage from "@/views/RenewPackage";
import WeekendSubscription from "@/views/WeekendSubscription";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "Page du magazine",
      description: "Votre magazine Week-end Sport et Loisir à portée de main",
    },
  },
  {
    path: "/:code",
    name: "Code",
    component: Home,
    meta: {
      title: "Page avec code du magazine",
      description: "Votre magazine Week-end Sport et Loisir à portée de main",
    },
  },
  {
    path: "/c/:mode",
    name: "HomeMode",
    component: Home,
    meta: {
      title: "Page du magazine",
      description: "Votre magazine Week-end Sport et Loisir à portée de main",
    },
  },
  {
    path: "/otp/:id",
    name: "otp",
    component: Otp,
    meta: {
      title: "Page OTP",
      description: "Page du code reçu par SMS",
    },
  },
  {
    path: "/renew/:code",
    name: "RenewPackage",
    component: RenewPackage,
    meta: {
      title: "Renouvellement d'abonnement",
      description: "Page pour le renouvellement d'un abonnement",
    },
  },
  {
    path: "/weekend/:phone?",
    name: "WeekendSubscription",
    component: WeekendSubscription,
    meta: {
      title: "Actualité de la semaine",
      description:
        "Découvrez les faits marquants de l’actualité au courant de cette semaine",
    },
  },
  {
    path: "/weekend/c/:mode",
    name: "Mode",
    component: WeekendSubscription,
    meta: {
      title: "Actualité de la semaine",
      description:
        "Découvrez les faits marquants de l’actualité au courant de cette semaine",
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
router.beforeEach((to, from) => {
  const title = to.meta?.title + " | Week-end Sport et Loisir" ?? "Week-end Sport et Loisir";
  document.title = title;
  document
    .querySelector('meta[name="description"]')
    ?.setAttribute("content", to.meta.description ?? "Week-end Sport et Loisir");
  document
    .querySelector('meta[property="og:title"]')
    ?.setAttribute("content", title);
  document
    .querySelector('meta[property="og:description"]')
    ?.setAttribute("content", to.meta.description ?? "Week-end Sport et Loisir");
  document
    .querySelector('meta[name="twitter:title"]')
    ?.setAttribute("content", title);
  document
    .querySelector('meta[name="twitter:description"]')
    ?.setAttribute("content", to.meta.description ?? "Week-end Sport et Loisir");
});

export default router;
